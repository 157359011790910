import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import OnboardingLoading from "shared/components/OnboardingLoading";
import * as WorkspaceService from "shared/services/workspace.service";
import { useAuth } from "shared/providers/AuthContext";
import { OnboardingStateEnum } from "shared/models/enums";

interface IOnboardingContext {
  isOnboarding: boolean;
  setIsOnboarding: (value: boolean) => void;
  createSampleWorkspace: () => void;
}

const OnboardingContext = createContext<IOnboardingContext>(null);

export const OnboardingProvider = ({ children }) => {
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [onboardingState, setOnboardingState] = useState(null);
  const { updateSessions } = useAuth();
  const navigate = useNavigate();

  const createSampleWorkspace = async () => {
    if (isOnboarding) return
    setIsOnboarding(true);

    const body = {
      name: "Sample workspace",
      handle: "sample-workspace",
    };
    setOnboardingState(OnboardingStateEnum.WorkspaceSetup);
    const res = await WorkspaceService.createWorkspace(body);
    setOnboardingState(OnboardingStateEnum.ProjectSetup);

    if (res.finishedCopyingSampleData) {
      await getWorkspaceProjects(res._id);
      setOnboardingState(OnboardingStateEnum.Done);
    } else {
      await checkWorkspaceStatus(res._id);
    }
  };

  const checkWorkspaceStatus = async (workspaceId) => {
    const response = await WorkspaceService.getWorkspace(workspaceId);
    if (response.finishedCopyingSampleData) {
      await getWorkspaceProjects(workspaceId);
    } else {
      // Call the function recursively after 5000ms
      setTimeout(() => checkWorkspaceStatus(workspaceId), 5000);
    }
  };

  const getWorkspaceProjects = async (workspaceId: string) => {
    const projectsRes = await WorkspaceService.getWorkspaceProjects(
      workspaceId
    );
    const sampleProject = projectsRes.data?.find(
      (proj) => proj.sample === true
    );
    if (sampleProject) {
      await updateSessions();
      setIsOnboarding(false);
      navigate(`/project/${workspaceId}/${sampleProject._id}/default`);
    }
  };

  return (
    <OnboardingContext.Provider
      value={{ isOnboarding, setIsOnboarding, createSampleWorkspace }}
    >
      {isOnboarding && <OnboardingLoading state={onboardingState} />}
      {children}
    </OnboardingContext.Provider>
  );
};

export function useOnboarding() {
  const context = useContext(OnboardingContext);
  if (context === null) {
    throw new Error("useOnboarding must be used within OnboardingProvider");
  }
  return context;
}
