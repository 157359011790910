export enum ShortcutTypes {
  copy = "copy",
  cut = "cut",
  undo = "undo",
  redo = "redo",
  select_all = "select_all",
  delete = "delete",
  save = "save",
  esc = "esc",
  group = "group",
  ungroup = "ungroup",
}

export enum NodeTypes {
  genericNode = "generic",
  clientNode = "client",
  serviceNode = "service",
  platformNode = "platform",
}

export enum UserRoles {
  OWNER = "Owner",
  ADMIN = "Admin",
  USER_READONLY = "User ReadOnly",
  USER_READWRITE = "User ReadWrite",
}

export enum ConnectionStatus {
  disconnected = "disconnected",
  connected = "connected",
  connecting = "connecting",
  failed = "failed",
  destroyed = "destroyed",
}

export enum EntityCategories {
  PLATFORM = "platform",
  COMPONENT = "component",
  DOCUMENT = "document",
  SKETCH = "sketch",
  REPOSITORY = "repository",
  ENVIRONMENT = "environment",
  SCHEMA = "schema",
  SOURCE = "source",
}

export enum EntityFormFieldType {
  TEXT = "text",
  RADIO = "radio",
  HIDDEN = "hidden",
  REPO = "repo",
}

export enum IntegrationType {
  GITLAB = "gitlab",
  GITHUB = "github",
  BITBUCKET = "bitbucket",
  RADAR = "radar",
}

export enum ProjectSourceType {
  ALL = "all",
  FILE = "file",
  RADAR = "radar",
  FLOWS = "flows",
  ENTITY = "entity",
  DEBUGGER = "debugger",
}

export enum GitObjectType {
  FILE = "file",
  DIRECTORY = "directory",
}

export enum EntityStateStatus {
  WAITING = "waiting",
  FETCHING = "fetching",
  FETCHED = "fetched",
  PATCHING = "patching",
  PATCHED = "patched",
  FAILED = "failed",
}
export enum StageStatus {
  UNSTAGED = 0,
  INDETERMINATE = 1,
  STAGED = 2,
}

export enum Endpoint {
  SOURCE = "source",
  TARGET = "target",
}

export enum PlatformChangeObject {
  COMPONENT = "component",
  EDGE = "edge",
  VIEW = "view",
}

export enum SystemViewTypes {
  ALL = "_all",
  DIFFS = "_diffs",
  CHANGES = "_changes",
}

export enum ChangesViewMode {
  NONE = "none",
  CHANGES = "changes",
  XRAY = "xray",
}

export enum SocketErrorCodes {
  UNAUTHORIZED = 401,
  ENTITY_NOT_FOUND = 404,
  ENTITY_REMOVED = 405,
}

export enum Steps {
  team = "team",
  member = "member",
  workspace = "workspace",
}

export enum OrganizationUnit {
  Team = "team",
  Workspace = "workspace",
}

export enum GitSourceType {
  CODE = "code",
  IMAGE = "image",
  VIDEO = "video",
}

export enum tlDrawEventTypes {
  COMMENT_TOOL_SELECTED = "commentToolSelected",
}

export enum PostHogEvents {
  SIGN_UP = "Sign up",
  SIGN_IN = "Sign in",
  ACCEPT_INVITATION = "Accept invitation",
  SETUP_RADAR = "Setup Radar",
  REMOVE_RADAR_INTEGRATION = "Remove Radar integration",
  CREATE_SESSION_RECORDING = "Create session recording",
  DELETE_SESSION_RECORDING = "Delete session recording",
  DELETE_PROJECT = "Delete Project",
  DELETE_TEAM = "Delete Team",
  DELETE_BRANCH = "Delete Branch",
  DELETE_ENTITY = "Delete Entity",
  ADD_COMPONENT_TO_PLATFORM = "Add component to Platform",
  ADD_DRAWING_TO_SKETCH = "Add drawing to sketch",
  UPDATE_NOTEBOOK = "Update notebook",
  SUPPORT_REQUEST = "Support request",
  CREATE_WORKSPACE = "Create Workspace",
  DELETE_WORKSPACE = "Delete Workspace",
  CREATE_PROJECT = "Create Project",
  CREATE_TEAM = "Create Team",
  CREATE_BRANCH = "Create Branch",
  CREATE_PLATFORM = "Create Platform",
  INVITE_USERS = "Invite Users",
  DELETE_TEAM_MEMBER = "Delete Team Member",
  DELETE_WORKSPACE_MEMBER = "Delete Workspace Member",
  LEAVE_WORKSPACE = "Leave Workspace",
  CONNECT_REPO_TO_PROJECT = "Connect repository to projects",
  CONNECT_TO_PUBLIC_REPO = "Connect to a public repo",
  SUBMITTED_DESIGN_REVIEW = "Submitted System Design Review",
  USER_CLICKED_TO_DEMO_LINK = "User clicked to contact us for a demo",
  USER_CHECKED_PRO_PLAN_BENEFITS = "User checked pro plan benefits on pricing page",
  USER_CLOSED_PRO_FEATURE_POPUP = "User closed pro feature pop up",
  USER_REMAINED_ON_FREE_PLAN = "User remained on the free plan after trial expiration",
}

export enum ComponentTypeEnum {
  GENERIC = "GENERIC",
  CLIENT = "CLIENT",
  SERVICE = "SERVICE",
  PLATFORM = "PLATFORM",
}

export enum SessionEventTypes {
  SESSION_SUBSCRIBE = "debug-session:workspace:subscribe",
  SESSION_UNSUBSCRIBE = "debug-session:workspace:unsubscribe",
  SESSION_STARTED = "debug-session:started",
  SESSION_STOPPED = "debug-session:stopped",
}

export enum SystemCatalogTabTypes {
  Components = "Components",
  APIs = "APIs",
  Platforms = "Platforms",
  Dependencies = "Dependencies",
  DataPipelines = "Data pipelines",
  Flows = "Flows",
  Debugger = "Debugger",
}

export enum SortingDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum OnboardingStateEnum {
  WorkspaceSetup = 1,
  ProjectSetup = 2,
  Done = 3,
}

export enum SubscriptionType {
  free = "free",
  teams = "teams",
  enterprise = "enterprise",
}

export enum UserActions {
  SETUP_DEBUGGER,
  RECORD_SESSION,
}

export enum CollectionTarget {
  API = "API",
  IMPLEMENTATION = "implementation",
}

export enum RefetchTargetType {
  API = "API",
}
