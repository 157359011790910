import { inputAnatomy } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
  defineStyle,
} from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    _readOnly: {
      bg: "gray.50",
      cursor: "not-allowed",
      color: "gray.600",
      _focus: {
        borderColor: "gray.300",
        boxShadow: "0 0 0 1px var(--chakra-colors-gray-300)",
      },
    },
  },
});

const md = defineStyle({
  fontSize: "sm",
});

const variants = {
  outline: definePartsStyle({
    field: {
      bg: "white",
      _placeholder: { color: "gray.400" },
      _focus: {
        borderColor: "brand.500",
        boxShadow: "0 0 0 1px var(--chakra-colors-brand-500)",
      },
    },
  }),
};

const sizes = {
  md: definePartsStyle({ field: md, addon: md }),
};

export const inputTheme = defineMultiStyleConfig({
  sizes,
  variants,
  baseStyle,
});

export const formLabelTheme = defineStyleConfig({
  baseStyle: defineStyle({
    fontSize: "sm",
    mb: 1,
  }),
});
