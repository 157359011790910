import { useToast } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";

const useMessage = () => {
  const toast = useToast();
  const handleError = useCallback(
    (error) => {
      if (error.isHandled) return;
      toast({
        status: "error",
        isClosable: true,
        title: error.message || "Something went wrong!",
        position: "bottom-left",
      });
    },
    [toast]
  );
  const warning = useCallback(
    (message: string) => {
      toast({
        status: "warning",
        isClosable: true,
        title: message,
        position: "bottom-left",
      });
    },
    [toast]
  );

  const success = useCallback(
    (message) => {
      toast({
        status: "success",
        isClosable: true,
        title: message,
        position: "bottom-left",
      });
    },
    [toast]
  );

  return useMemo(
    () => ({ handleError, success, warning }),
    [handleError, success, warning]
  );
};

export default useMessage;
