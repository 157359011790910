import SessionDebugger from "@multiplayer-app/session-debugger";
import { Suspense } from "react";
import posthog from "posthog-js";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

// Providers
import { SocketProvider } from "shared/providers/SocketContext";
import { AuthProvider } from "shared/providers/AuthContext";
import { AnalyticsProvider } from "shared/providers/AnalyticsContext";
import { GeneralModalsProvider } from "shared/providers/GeneralModalsContext";
import { ConnectionProvider } from "shared/providers/ConnectionContext";
import { BillingProvider } from "shared/providers/BillingContext";
import { OnboardingProvider } from "shared/providers/OnboardingContext";

import App from "./App";
import theme from "./theme";
import ErrorHandler from "./ErrorHandler";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.REACT_APP_SESSION_DEBUGGER_KEY) {
  SessionDebugger.init({
    version: "0.0.1",
    application: "multiplayer-web-app",
    environment: process.env.REACT_APP_PLATFORM_ENV,
    apiKey: process.env.REACT_APP_SESSION_DEBUGGER_KEY,
    exporterApiBaseUrl: process.env.REACT_APP_SESSION_DEBUGGER_API_BASE_URL,
    canvasEnabled: true,
    showWidget: true,
    ignoreUrls: [
      /https:\/\/cdn\.jsdelivr\.net\/.*/,
      /https:\/\/bam\.nr-data\.net\/.*/,
    ],
    propagateTraceHeaderCorsUrls: new RegExp(
      `${process.env.REACT_APP_API_BASE_URL}\.*`,
      "i"
    ),
    schemifyDocSpanPayload: true,
    maskDebSpanPayload: false,
    docTraceRatio: 0.3,
    sampleTraceRatio: 0.3,
    maxCapturingHttpPayloadSize: 100000,
    disableCapturingHttpPayload: false
  });
}

if (process.env.REACT_APP_POSTHOG_KEY) {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: "https://app.posthog.com",
  });
}

root.render(
  // <React.StrictMode>
  // Temporary fix for this issue: Invariant failed: Cannot find droppable entry with id [board]
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <SocketProvider>
        <ConnectionProvider>
          <AnalyticsProvider>
            <GeneralModalsProvider>
              <AuthProvider>
                <BillingProvider>
                  <OnboardingProvider>
                  <ErrorHandler />
                  <Suspense>
                    <App />
                  </Suspense>
                  </OnboardingProvider>
                </BillingProvider>
              </AuthProvider>
            </GeneralModalsProvider>
          </AnalyticsProvider>
        </ConnectionProvider>
      </SocketProvider>
    </ChakraProvider>
  </BrowserRouter>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
