import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSocket } from "shared/providers/SocketContext";
import { useAuth } from "shared/providers/AuthContext";
import { useBilling } from "shared/providers/BillingContext";
import {
  apiInstance,
  gitInstance,
  radarInstance,
  versionInstance,
  notebookInstance,
} from "shared/api";
import { AxiosInstance } from "axios";

const ErrorHandler = () => {
  const { clearAuthData } = useAuth();
  const { showProFeaturePopup } = useBilling();
  const navigate = useNavigate();

  const { subscribe } = useSocket();

  useEffect(() => {
    const handleAuthError = (error) => {
      if (error && error.code === 401 && window.location.pathname !== "/auth") {
        localStorage.setItem("redirectTo", window.location.pathname);
        navigate("/auth");
        clearAuthData();
      }
    };

    const handlePlanLimitationError = (error) => {
      if (error && error.statusCode === 402) {
        error.isHandled = true;
        showProFeaturePopup();
      }
    };

    const errorHandler = (error) => {
      handleAuthError(error);
      handlePlanLimitationError(error);
      return Promise.reject(error);
    };

    const instances = [
      apiInstance,
      gitInstance,
      radarInstance,
      versionInstance,
      notebookInstance,
    ];

    const interceptors: [AxiosInstance, number][] = instances.map(
      (instance) => [
        instance,
        instance.interceptors.response.use(undefined, errorHandler),
      ]
    );

    return () => {
      interceptors.forEach(([instance, interceptor]) => {
        instance.interceptors.response.eject(interceptor);
      });
    };
  }, [clearAuthData, subscribe, navigate]);

  return null;
};

export default ErrorHandler;
