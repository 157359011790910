import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const variants = {
  outline: defineStyle({
    bg: "white",
    _placeholder: { color: "gray.400" },
    _focus: {
      borderColor: "brand.500",
      boxShadow: "0 0 0 1px var(--chakra-colors-brand-500)",
    },
    _readOnly: {
      bg: "gray.50",
      cursor: "not-allowed",
      color: "gray.600",
      _focus: {
        borderColor: "gray.300",
        boxShadow: "0 0 0 1px var(--chakra-colors-gray-300)",
      },
    },
  }),
};

export const textareaTheme = defineStyleConfig({ variants });
