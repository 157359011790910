import { Observable } from 'lib0/observable'
import { SessionDebuggerConfigs } from 'src/types'
import {
  UIManager,
} from './UIManager'
import {
  buttonStates,
  ButtonState,
  BUTTON_STATE_ENUM,
} from './buttonStateConfigs'
import 'rrweb-player/dist/style.css'

const POSITION_STATE_KEY = 'mp-recorder-button-position'
const POPOVER_WIDTH = 300
const POPOVER_DISTANCE_FROM_BUTTON = 25
const NON_DRAGGABLE_OFFSET = 10

export class SessionWidget extends Observable<
'toggle' | 'pause' | 'cancel' | 'preview'
> {
  public readonly recorderButton: HTMLButtonElement
  private readonly initialPopover: HTMLElement
  private readonly finalPopover: HTMLElement
  private readonly previewModal: HTMLElement
  private _isStarted: boolean = false
  private _isPaused: boolean = false
  private _isDragging: boolean = false
  private _dragStarted: boolean = false
  private _recorderPlacement: string = ''
  private _error: string = ''
  private _initialPopoverVisible: boolean = false
  private _finalPopoverVisible: boolean = false
  private _previewModalVisible: boolean = false
  private uiManager: UIManager
  private readonly commentTextarea: HTMLTextAreaElement | null = null
  private buttonDraggabilityObserver!: MutationObserver
  public buttonClickExternalHandler: (() => boolean | void) | null = null


  private set buttonState(newState: ButtonState) {
    const { icon, tooltip, classes, excludeClasses } = buttonStates[newState]
    if (newState === 'CANCEL') {
      this.buttonDraggabilityObserver.observe(this.recorderButton, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class'],
      })
    } else {
      this.buttonDraggabilityObserver.disconnect()
    }
    this.updateButton(icon, tooltip, excludeClasses, classes)
  }

  private set initialPopoverVisible(v: boolean) {
    this._initialPopoverVisible = v
    this.initialPopover?.classList.toggle('hidden', !v)
  }

  private set finalPopoverVisible(v: boolean) {
    this._finalPopoverVisible = v
    this.finalPopover?.classList.toggle('hidden', !v)
  }

  private set previewModalVisible(v: boolean) {
    this._previewModalVisible = v
    this.previewModal?.classList.toggle('hidden', !v)
  }

  public get error(): string {
    return this._error
  }

  public set error(v: string) {
    this._error = v
    this.updateTooltip()
  }

  public set isStarted(v: boolean) {
    this._isStarted = v
    this.recorderButton.classList.toggle('is-started', this._isStarted)
    if (this._isStarted) {
      this.buttonState = BUTTON_STATE_ENUM.RECORDING
    }
  }

  public set isPaused(v: boolean) {
    this._isPaused = v
  }

  constructor() {
    super()

    this.recorderButton = document.createElement('button')
    this.initialPopover = document.createElement('div')
    this.finalPopover = document.createElement('div')
    this.previewModal = document.createElement('div')

    this.uiManager = new UIManager(
      this.recorderButton,
      this.initialPopover,
      this.finalPopover,
      this.previewModal,
    )
    this.uiManager.setRecorderButtonProps()
    this.uiManager.setInitialPopoverProps()
    this.uiManager.setFinalPopoverProps()
    this.uiManager.setPreviewModalProps()

    this.commentTextarea = this.finalPopover.querySelector(
      '.mp-session-debugger-popover-textarea',
    )
    this.observeButtonDraggableMode()
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  private handleClickOutside(event) {
    const targetElement = document.querySelector('.mp-initial-popover')
    const ignoredElement = 'mp-session-debugger-button'
    if (this._initialPopoverVisible && !this._isStarted && event?.target && !targetElement?.contains(event.target) && !event.target.classList.contains(ignoredElement)) {
      this.handleCloseInitialPopover()
    }
  }

  private observeButtonDraggableMode() {
    this.buttonDraggabilityObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          const oldClassName = mutation.oldValue
          const newClassName = mutation.target['className']
          if (
            (oldClassName?.includes('no-draggable') &&
              !newClassName.includes('no-draggable')) ||
            (newClassName?.includes('no-draggable') &&
              !oldClassName?.includes('no-draggable'))
          ) {
            // draggable mode was changed
            this.initialPopoverVisible = false
            this.finalPopoverVisible = false
            this.previewModalVisible = false
          }
        }
      }
    })
  }

  private updateTooltip() {
    if (this._error) {
      this.recorderButton.dataset['tooltip'] = this._error
    }
  }

  init(options: SessionDebuggerConfigs) {
    this.addEventListeners()
    const elements = [this.previewModal]
    if (options.showWidget) {
      elements.push(
        this.recorderButton,
        this.initialPopover,
        this.finalPopover,
      )
    }
    this.appendElements(elements)
    if (options.showWidget && options.recordButtonPlacement) {
      this.recorderButton.classList.add(options.recordButtonPlacement)
      this._recorderPlacement = options.recordButtonPlacement
      this.addRecorderDragFunctionality()
    }
  }

  private addRecorderDragFunctionality() {
    this._isDragging = false
    this._dragStarted = false
    let isOnLeftHalfOfScreen = false

    const loadStoredPosition = () => {
      const savedPosition = localStorage.getItem(POSITION_STATE_KEY)
      if (!savedPosition) {
        return
      }
      const { right, bottom } = JSON.parse(savedPosition)

      if (right !== null && bottom !== null) {
        requestAnimationFrame(() => {
          this.recorderButton.classList.remove(this._recorderPlacement)
          this.recorderButton.style.right = `${right}px`
          this.recorderButton.style.bottom = `${bottom}px`
          isOnLeftHalfOfScreen = Number(right) > window.innerWidth / 2
          this.recorderButton.classList.toggle(
            'button-leftside',
            isOnLeftHalfOfScreen,
          )
          this.updatePopoverPosition()
        })
      }
    }

    const savePosition = (right: number, bottom: number) => {
      const position = { right, bottom }
      localStorage.setItem(POSITION_STATE_KEY, JSON.stringify(position))
    }

    this.recorderButton.addEventListener('mousedown', (e) => {
      const onMouseUp = () => {
        const isDraggable =
          !this.recorderButton.classList.contains('no-draggable')
        if (this._isDragging || !isDraggable) {
          this.recorderButton.classList.toggle(
            'button-leftside',
            isOnLeftHalfOfScreen,
          )
          this._isDragging = false
          if (this._isPaused) {
            this.finalPopoverVisible = true
          }
          document.body.style.userSelect = ''

          if (isDraggable) {
            const finalRight = parseFloat(this.recorderButton.style.right)
            const finalBottom = parseFloat(this.recorderButton.style.bottom)
            savePosition(finalRight, finalBottom)
            document.removeEventListener('mousemove', onMouseMove)
          }

          if (!this._dragStarted) {
            this.updatePopoverPosition()
            this.onRecordingButtonClick(e)
          }
        }
        this.recorderButton.classList.remove('no-hover')
        document.removeEventListener('mouseup', onMouseUp)
      }
      if (this.recorderButton.classList.contains('no-draggable')) {
        this._isDragging = false
        this._dragStarted = false
        document.addEventListener('mouseup', onMouseUp)
        return
      }
      this.recorderButton.classList.add('no-hover')
      this._isDragging = true
      this._dragStarted = false
      const startX = e.clientX
      const startY = e.clientY
      const buttonRect = this.recorderButton.getBoundingClientRect()
      const viewportWidth = window.innerWidth
      const viewportHeight = window.innerHeight

      const onMouseMove = (moveEvent: MouseEvent) => {
        const deltaX = moveEvent.clientX - startX
        const deltaY = moveEvent.clientY - startY

        // If mouse moved significantly, consider it a drag
        if (Math.abs(deltaX) > 3 || Math.abs(deltaY) > 3) {
          this._dragStarted = true
          this.initialPopoverVisible = false
          this.finalPopoverVisible = false
          this.recorderButton.classList.remove(this._recorderPlacement)
          if (!this._isStarted && !this._isPaused) {
            this.buttonState = BUTTON_STATE_ENUM.READY
          }
          this.updatePopoverPosition()

          const newLeft = Math.max(
            0,
            Math.min(viewportWidth - buttonRect.width, buttonRect.left + deltaX),
          )
          const newTop = Math.max(
            0,
            Math.min(
              viewportHeight - buttonRect.height,
              buttonRect.top + deltaY,
            ),
          )
          const newRight = viewportWidth - newLeft - buttonRect.width
          const newBottom = viewportHeight - newTop - buttonRect.height
          isOnLeftHalfOfScreen = newRight > window.innerWidth / 2

          requestAnimationFrame(() => {
            this.recorderButton.style.right = `${newRight}px`
            this.recorderButton.style.bottom = `${newBottom}px`
          })
        }
      }

      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)

      document.body.style.userSelect = 'none'
      e.preventDefault()
    })

    loadStoredPosition()
  }

  private updatePopoverPosition() {
    const { top, right, bottom, left } =
      this.recorderButton.getBoundingClientRect()
    const isDraggable = !this.recorderButton.classList.contains('no-draggable')
    // Todo update fixed height and width calculation
    const POPOVER_HEIGHT = this._isStarted ? 400 : 300
    const VIEWPORT_WIDTH = window.innerWidth
    const VIEWPORT_HEIGHT = window.innerHeight
    // Calculate initial positions
    let popoverBottom = VIEWPORT_HEIGHT - top + POPOVER_DISTANCE_FROM_BUTTON + (isDraggable ? 0 : NON_DRAGGABLE_OFFSET)
    let popoverRight = VIEWPORT_WIDTH - right

    // Ensure popover stays within viewport
    if (popoverBottom + POPOVER_HEIGHT > VIEWPORT_HEIGHT) {
      popoverBottom =
        VIEWPORT_HEIGHT -
        (bottom + POPOVER_HEIGHT) +
        (isDraggable ? 0 : NON_DRAGGABLE_OFFSET)
    }
    if (popoverRight + POPOVER_WIDTH > VIEWPORT_WIDTH) {
      popoverRight = VIEWPORT_WIDTH - (left + POPOVER_WIDTH)
    }

    const updatePopoverStyles = (popover: HTMLElement) => {
      popover.style.right = `${popoverRight}px`
      popover.style.bottom = `${popoverBottom}px`
      popover.style.left = 'unset'
    }

    requestAnimationFrame(() => {
      this.initialPopover && updatePopoverStyles(this.initialPopover)
      this.finalPopover && updatePopoverStyles(this.finalPopover)
    })
  }

  private addEventListeners() {
    const events = [
      {
        target: this.initialPopover,
        selector: '.mp-start-recording',
        handler: this.startRecording.bind(this),
      },
      {
        target: this.initialPopover,
        selector: '.mp-session-debugger-modal-close',
        handler: this.handleCloseInitialPopover.bind(this),
      },
      {
        target: this.finalPopover,
        selector: '.mp-stop-recording',
        handler: this.handleStopRecording.bind(this),
      },
      {
        target: this.finalPopover,
        selector: '.mp-preview-recording',
        handler: this.handlePreviewRecording.bind(this),
      },
      {
        target: this.finalPopover,
        selector: '.mp-session-debugger-dismiss-button',
        handler: this.handleDismissRecording.bind(this),
      },
      {
        target: this.previewModal,
        selector: '.mp-session-debugger-modal-close',
        handler: this.handleModalClose.bind(this),
      },
      {
        target: this.previewModal,
        selector: '.mp-dismiss-recording',
        handler: this.handleDismissRecording.bind(this),
      },
      {
        target: this.previewModal,
        selector: '.mp-send-recording',
        handler: this.handleSendRecording.bind(this),
      },
    ]

    events.forEach(({ target, selector, handler }) => {
      this.addListener(target, selector, handler)
    })
  }

  private handleStopRecording() {
    this.onStop()
    this.finalPopoverVisible = false
    this.buttonState = BUTTON_STATE_ENUM.SENT
    this.resetRecordingButton()
  }

  private handleCloseInitialPopover() {
    this.initialPopoverVisible = false
    this.buttonState = BUTTON_STATE_ENUM.READY
    document.removeEventListener('click', this.handleClickOutside)
  }

  public onRequestError() {
    this.initialPopoverVisible = false
    this.finalPopoverVisible = false
    this.buttonState = BUTTON_STATE_ENUM.READY
    document.removeEventListener('click', this.handleClickOutside)
  }

  private handlePreviewRecording() {
    this.previewModalVisible = true
    this.emit('preview', [])
  }

  private handleDismissRecording() {
    this.onCancel()
    this.finalPopoverVisible = !this._finalPopoverVisible
    this.previewModalVisible = false
    this.buttonState = BUTTON_STATE_ENUM.READY
    if (this.commentTextarea) {
      this.commentTextarea.value = ''
    }
  }

  private handleModalClose() {
    this.previewModalVisible = !this._previewModalVisible
    const replayContainer = document.getElementById('mpPlayerFrame')
    if (replayContainer) {
      replayContainer.innerHTML = ''
    }
  }

  private handleSendRecording() {
    this.previewModalVisible = !this._previewModalVisible
    this.onStop()
    this.finalPopoverVisible = false
    this.buttonState = BUTTON_STATE_ENUM.SENT
    this.resetRecordingButton()
  }

  private resetRecordingButton() {
    setTimeout(() => {
      this.buttonState = BUTTON_STATE_ENUM.READY
    }, 1500)
  }

  private appendElements(elements: HTMLElement[]) {
    const rootWrapper = document.createElement('mp-root')
    rootWrapper.classList.add('mp-root-wrapper')
    elements.forEach((element) => rootWrapper.appendChild(element))
    document.body.appendChild(rootWrapper)
  }

  private addListener(
    element: HTMLElement | null,
    selector: string,
    handler: EventListener,
    event: string = 'click',
  ) {
    element?.querySelector(selector)?.addEventListener(event, handler)
  }

  private onRecordingButtonClick(e) {
    if (this.buttonClickExternalHandler) {
      const shouldPropagate = this.buttonClickExternalHandler()
      if (shouldPropagate === false) {
        e.preventDefault()
        return
      }
    }

    if (this._isPaused) {
      this.onCancel()
      this.finalPopoverVisible = false
      this.buttonState = BUTTON_STATE_ENUM.READY
      return
    }

    if (this._isStarted) {
      this.finalPopoverVisible = !this._finalPopoverVisible
      this.buttonState = BUTTON_STATE_ENUM.CANCEL
      this.onPause()
    } else {
      this.buttonState = this._initialPopoverVisible
        ? BUTTON_STATE_ENUM.READY
        : BUTTON_STATE_ENUM.CANCEL
      this.initialPopoverVisible = !this._initialPopoverVisible
      if (this._initialPopoverVisible) {
        document.addEventListener('click', this.handleClickOutside)
      } else {
        document.removeEventListener('click', this.handleClickOutside)
      }
    }
  }

  private updateButton(
    innerHTML: string,
    tooltip: string,
    excludeClasses?: string[],
    classes?: string[],
  ) {
    if (!this.recorderButton) return
    this.recorderButton.innerHTML = `${innerHTML}`
    this.recorderButton.dataset['tooltip'] = tooltip
    if (excludeClasses) {
      this.recorderButton.classList.remove(...excludeClasses)
    }
    if (classes) {
      this.recorderButton.classList.add(...classes)
    }
  }

  private startRecording() {
    this.initialPopoverVisible = false
    this.buttonState = BUTTON_STATE_ENUM.RECORDING
    this.onStart()
  }

  private onStart() {
    if (!this.recorderButton) return
    this.emit('toggle', [true])
  }

  private onStop() {
    if (!this.recorderButton) return

    if (this.commentTextarea) {
      this.emit('toggle', [false, this.commentTextarea.value])
      this.commentTextarea.value = ''
      return
    }

    this.emit('toggle', [false, ''])
  }

  private onPause() {
    this.emit('pause', [])
  }

  private onCancel() {
    this.emit('cancel', [])
  }

  enable() {
    if (!this.recorderButton) return
    this.recorderButton.disabled = false
    this.recorderButton.style.opacity = '1'
  }

  disable() {
    if (!this.recorderButton) return
    this.recorderButton.disabled = true
    this.recorderButton.style.opacity = '0.5'
  }

  destroy() {
    if (!this.recorderButton) return
    document.body.removeChild(this.recorderButton)
    document.removeEventListener('click', this.handleClickOutside)
  }
}
