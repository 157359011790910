import slugify from "slugify";

slugify.extend({
  _: "",
  $: "",
  "^": "",
  "%": "",
  "&": "",
  "<": "",
  ">": "",
  "|": "",
  "¢": "",
  "£": "",
  "@": "",
  '"': "",
  "]": "",
  "[": "",
  "=": "",
  "?": "",
  ",": "",
  ".": "",
  "/": "",
  "\\": "",
  ";": "",
});

export default slugify;
